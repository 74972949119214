import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAlertColumnName} from "../type";
import {RootState} from "../../store";


export const ALERT_KEY = "alerts";

interface State {
    alert: IAlertColumnName[];
    error: string;
    alertLoading: boolean;
}

const initialState: State = {
    alert: [],
    error: '',
    alertLoading: true,
};

const selectAlertSlice = (state: RootState) => state.alerts;

const AppSlice = createSlice({
    name: ALERT_KEY,
    initialState,
    reducers: {
        setAlertLoading(state: State) {
            state.alertLoading = true;
        },
        setAlert(state: State, action: PayloadAction<IAlertColumnName[]>) {
            state.alert = action.payload;
            state.alertLoading = false;
        },
        clearAlert(state: State, action: PayloadAction<string>) {
            state.alert = [];
            state.alertLoading = false;
            state.error = action.payload;
        }
    },
});
export const {setAlertLoading, setAlert, clearAlert} = AppSlice.actions;

export const alertReducer = AppSlice.reducer;
export const alertSelector = createSelector(selectAlertSlice, (state) => ({
    alert: state.alert,
    alertLoading: state.alertLoading,
    error: state.error
}));


